<template>
  <div>
    <b-nav-item-dropdown
      class="dropdown-cart mr-25"
      menu-class="dropdown-menu-media"
      right
      @show="fetchItems(getUserData.id)"
    >
      <template #button-content>
        <feather-icon
          :badge="totalItems"
          class="text-body"
          icon="ShoppingCartIcon"
          size="21"
        />
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">{{ $t("cart") }}</h4>
          <b-badge pill variant="light-primary">
            {{ totalItems }} {{ $t("product") }}
          </b-badge>
        </div>
      </li>

      <!-- Cart Items -->
      <vue-perfect-scrollbar
        v-if="items.length"
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <b-media v-for="(item, index) in items" :key="index">
          <feather-icon
            icon="XIcon"
            class="cart-item-remove cursor-pointer"
            @click.stop="removeItemFromCart(item.saleitemid)"
          />
          <div class="media-heading">
            <h6 class="cart-item-title">
              <b-link class="text-body">
                {{ item.saleitemname ? item.saleitemname : item.Saleitemname1 }}
              </b-link>
            </h6>
          </div>
          <div class="cart-item-qty ml-1">
            <b-form-spinbutton
              min="1"
              size="sm"
              :value="item.quantity"
              @input="(val) => changeQuantity(val, item)"
            />
          </div>
          <h5 class="cart-item-price">
            ₺{{ Math.floor(parseFloat(item.orjprice)) }}
          </h5>
        </b-media>
      </vue-perfect-scrollbar>

      <!-- Cart Footer -->
      <li v-if="items.length" class="dropdown-menu-footer">
        <div class="d-flex justify-content-between mb-1">
          <h6 class="font-weight-bolder mb-0">{{ $t("total") }}:</h6>
          <h6 class="text-primary font-weight-bolder mb-0">
            ₺{{ totalAmount }}
          </h6>
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          @click="checkout"
        >
          {{ $t("purchaseNow") }}
        </b-button>
      </li>
      <p v-if="!items.length" class="m-0 p-1 text-center">
        {{ $t("cartEmpty") }}
      </p>
    </b-nav-item-dropdown>
    <b-modal
      id="checkout"
      ref="checkoutModal"
      :title="$t('nutrientMatrix')"
      size="md"
      :ok-title="$t('ok')"
      ok-only
    >
      <b-card no-body class="min-h-100" style="max-width: 100%">
        <div class="mh-full">
          <div v-if="error">
            {{ $t("pleaseBeforeBuyingFillYourInformation") }}
            <b-link to="/company/general"> {{ $t("generalSettings") }} </b-link>
          </div>
          <iframe
            v-if="token"
            id="paytriframe"
            :src="'https://www.paytr.com/odeme/guvenli/' + token"
            frameborder="0"
            scrolling="true"
            style="width: 100%"
          ></iframe>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BFormSpinbutton,
  BButton,
  BModal,
  BCard,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import { mapState, mapActions, mapMutations } from "vuex";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BFormSpinbutton,
    VuePerfectScrollbar,
    BButton,
    BModal,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      getUserData: getUserData(),
      token: null,
      error: null,
    };
  },
  computed: {
    ...mapState({
      basket: (state) => state.purchaseModule.basket,
    }),
    items() {
      if (this.basket[0]) {
        return this.basket[0].Items;
      } else if (this.basket.Items) {
        return this.basket.Items;
      }
      return [];
    },
    totalAmount() {
      let total = 0;
      this.items.forEach((i) => {
        total += Math.floor(parseFloat(i.orjprice)) * i.quantity;
      });
      return total;
    },
    totalItems() {
      let total = 0;
      this.items.forEach((i) => {
        total += i.quantity;
      });
      return total;
    },
  },
  watch: {},
  mounted() {
    this.fetchItems(getUserData().id);
  },
  methods: {
    ...mapActions({
      fetchItems: "purchaseModule/getUserBasket",
      saveBasket: "purchaseModule/saveUserBasket",
    }),
    ...mapMutations({
      setBasket: "purchaseModule/SET_BASKET",
    }),
    removeItemFromCart(productId) {
      const items = this.items.filter((item) => item.saleitemid != productId);
      var basket = {
        Basket: this.basket.Basket,
        Items: items,
      };
      this.saveBasket();
      this.setBasket(basket);
    },
    async checkout() {
      var { data } = await this.$http.get("Payment/GetIframeToken");
      if (data.status == "ERROR") {
        this.error = true;
      } else {
        this.token = data.statusdata;
      }
      this.$refs.checkoutModal.show();
    },
    changeQuantity(val, item) {
      if (val != item.quantity) {
        item.quantity = val;
        this.saveBasket();
        this.setBasket(this.basket);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
.mh-full {
  @media (max-width: 479px) {
    height: 150vh !important;
  }
  height: 128vh !important;
}
#paytriframe {
  min-height: 100% !important;
  overflow: scroll;
}
#paytriframe body {
  min-height: 100% !important;
  overflow: scroll;
}
#paytriframe form {
  overflow: scroll !important;
}
</style>
