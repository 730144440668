<template>
  <b-nav-item @click="toggleSkin">
    <feather-icon size="21" :icon="`${isDark ? 'Sun' : 'Moon'}Icon`" />
  </b-nav-item>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { computed } from "@vue/composition-api";
import { BNavItem } from "bootstrap-vue";

export default {
  components: {
    BNavItem,
  },
  setup() {
    const { skin } = useAppConfig();

    const isDark = computed(() => skin.value === "dark");

    const toggleSkin = () => {
      skin.value = isDark.value ? "light" : "dark";
      localStorage.setItem("isThemeEdited", true);
    };

    return { skin, isDark, toggleSkin };
  },
};
</script>
